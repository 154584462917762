<template>
  <div class="body-1">
    <table>
      <tr v-for="(item, i) in keys" :key="i">
        <td>{{statuses[item].agency}}</td>
        <td>{{statuses[item].timestamp}}</td>
        <td>
          <table>
            <tr v-for="(update, i) in statuses[item].updates" :key="i">
              <td>{{update.message}}</td>
              <td>{{update.machine}}</td>
              <td>{{update.date}}</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>


<script>
const signalR = require("@microsoft/signalr");

import { getIdToken } from "../../auth";
import moment from "moment";

export default {
  data() {
    return {
      connection: undefined,
      statuses: {}
    };
  },
  computed: {
    keys() {
      return Object.keys(this.statuses).reverse();
    }
  },
  created() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("/hubs/status", { accessTokenFactory: () => getIdToken() })
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    this.connection.on("newStatus", (agency, timestamp, machine) => {
      var key = `${agency}-${timestamp}`;
      if (!Object.prototype.hasOwnProperty.call(this.statuses, key)) {
        this.$set(this.statuses, key, {});
        this.$set(this.statuses[key], "agency", agency);
        this.$set(this.statuses[key], "timestamp", timestamp);
        this.$set(this.statuses[key], "updates", []);
      }

      this.statuses[key].updates.push({
        date: moment().format("h:mm:ss:SS a"),
        machine: machine,
        message: "Status received"
      });
    });
    this.connection.on("processing", (agency, timestamp, machine) => {
      var key = `${agency}-${timestamp}`;
      if (!Object.prototype.hasOwnProperty.call(this.statuses, key)) {
        this.$set(this.statuses, key, {});
        this.$set(this.statuses[key], "agency", agency);
        this.$set(this.statuses[key], "timestamp", timestamp);
        this.$set(this.statuses[key], "updates", []);
      }
      this.statuses[key].updates.push({
        date: moment().format("h:mm:ss:SS a"),
        machine: machine,
        message: "Processing started"
      });
    });
    this.connection.on("complete", (agency, timestamp, machine) => {
      var key = `${agency}-${timestamp}`;
      if (!Object.prototype.hasOwnProperty.call(this.statuses, key)) {
        this.$set(this.statuses, key, {});
        this.$set(this.statuses[key], "agency", agency);
        this.$set(this.statuses[key], "timestamp", timestamp);
        this.$set(this.statuses[key], "updates", []);
      }
      this.statuses[key].updates.push({
        date: moment().format("h:mm:ss:SS a"),
        machine: machine,
        message: "Processing completed"
      });
    });

    this.connection.start();
  }
};
</script>

